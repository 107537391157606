import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../_App/Redux/hooks";
import {
  findTicketByCode,
  statsTickets,
} from "../../_App/Redux/Slices/tickets/ticketSlice";
import Ticket from "../Tickets/Ticket";
import FicheInterventionModal from "../Interventions/FicheInterventionModal";
import { Form, Button } from "react-bootstrap";
import * as XLSX from "xlsx"; // Import xlsx

const TableTickets = ({ setMergedData }: any) => {
  const Tickets = useAppSelector(statsTickets);
  const dispatch = useAppDispatch();

  const [currentPage, setCurrentPage] = useState(1);
  const ticketsPerPage = 15;
  const [searchTerm, setSearchTerm] = useState("");

  const filteredData = Tickets.filter(
    (item: any) =>
      item.machine_code?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.type?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  useEffect(() => {
    setCurrentPage(1);
  }, [searchTerm]);

  const indexOfLastTicket = currentPage * ticketsPerPage;
  const indexOfFirstTicket = indexOfLastTicket - ticketsPerPage;
  const currentTickets = filteredData.slice(
    indexOfFirstTicket,
    indexOfLastTicket
  );
  const totalPages = Math.ceil(filteredData.length / ticketsPerPage);

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const [ShowModal, setShowModal] = useState(false);
  const [selectedTicket, setSelectedTicket] = useState();
  const [ticket, setTicket] = useState(undefined);
  const [showIntervention, setShowIntervention] = useState(false);
  const [selectedIntervention, setSelectedIntervention] = useState(undefined);

  useEffect(() => {
    const fetch = async () => {
      if (selectedTicket) {
        const res = await dispatch(findTicketByCode(selectedTicket));
        if (res.payload) {
          setTicket(res.payload);
        }
      }
    };
    fetch();
  }, [selectedTicket, dispatch]);

  const exportToExcel = () => {
    const dataToExport = filteredData.map((ticket: any) => ({
      Ticket: ticket.code,
      Type: ticket.type,
      Objet: ticket.objet,
      Machine: ticket.machine_code,
      ODP: ticket.odf_code,
      Debut: ticket.date_debut?.replace("T", " ").split(".")[0],
      Fin: ticket.date_fin?.replace("T", " ").split(".")[0],
      Durée: ticket.duration,
      Interventions: ticket.intervention_count,
      Techniciens: ticket.user_names,
    }));

    const worksheet = XLSX.utils.json_to_sheet(dataToExport);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Tickets");
    XLSX.writeFile(workbook, "Tickets.xlsx");
  };

  return (
    <>
      <Ticket
        ShowModal={ShowModal}
        setShowModal={setShowModal}
        selectedTicket={ticket}
        setSelectedTicket={setSelectedTicket}
        setSelectedIntervention={setSelectedIntervention}
        setShowIntervention={setShowIntervention}
      />
      <FicheInterventionModal
        ShowModal={showIntervention}
        setShowModal={setShowIntervention}
        intervention={selectedIntervention}
        setIntervention={setSelectedIntervention}
      />
      <div
        className="row"
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div className="col-10">
          {" "}
          <Form.Group controlId="formSearch">
            <Form.Control
              type="text"
              placeholder="Recherche par machine ou type ..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </Form.Group>
        </div>
        <div className="col-2">
          {" "}
          <Button
            variant="success"
            onClick={exportToExcel}
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <i className="fas fa-file-excel mr-2"></i>
            Export
          </Button>
        </div>
      </div>

      <table id="style-3" className="table style-3 table-hover">
        <thead>
          <tr>
            <th>Ticket</th>
            <th>Type</th>
            <th>Objet</th>
            <th>Machine</th>
            <th>ODP</th>
            <th>Debut</th>
            <th>Fin</th>
            <th>Durée</th>
            <th>Interventions</th>
            <th>Techniciens</th>
          </tr>
        </thead>
        <tbody>
          {currentTickets.length > 0 ? (
            currentTickets.map((ticket: any, index: any) => (
              <tr key={index}>
                <td
                  onClick={() => {
                    setSelectedTicket(ticket.code);
                    setShowModal(true);
                  }}
                >
                  <a className="link" style={{ cursor: "pointer" }}>
                    {ticket.code}
                  </a>
                </td>
                <td>{ticket.type}</td>
                <td>{ticket.objet}</td>
                <td>{ticket.machine_code}</td>
                <td>{ticket.odf_code}</td>
                <td>{ticket.date_debut?.replace("T", " ").split(".")[0]}</td>
                <td>{ticket.date_fin?.replace("T", " ").split(".")[0]}</td>
                <td>{ticket.duration}</td>
                <td>{ticket.intervention_count}</td>
                <td>
                  {ticket?.user_names
                    ?.split(",")
                    .map((name: any, index: any) => (
                      <span key={index}>
                        {name}
                        <br />
                      </span>
                    ))}
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={10}>No data available</td>
            </tr>
          )}
        </tbody>
      </table>

      <div className="pagination" style={{ overflow: "auto", margin: "5px" }}>
        <button
          disabled={currentPage === 1}
          onClick={() => handlePageChange(currentPage - 1)}
          className="btn"
        >
          Préc.
        </button>

        {Array.from({ length: totalPages }, (_, i) => (
          <button
            key={i + 1}
            className={currentPage === i + 1 ? "btn active" : "btn"}
            onClick={() => handlePageChange(i + 1)}
          >
            {i + 1}
          </button>
        ))}

        <button
          disabled={currentPage === totalPages}
          onClick={() => handlePageChange(currentPage + 1)}
          className="btn"
        >
          Suiv.
        </button>
      </div>
    </>
  );
};

export default TableTickets;
