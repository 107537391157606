import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import {
  Auth,
  currentUser,
  logout,
} from "../../_App/Redux/Slices/auth/authSlice";
import { useAppDispatch, useAppSelector } from "../../_App/Redux/hooks";

const Sidebar = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const currentPath = location.pathname;
  const user = useAppSelector(currentUser);
  // Extract the "users" segment
  const segment = currentPath.split("/").filter(Boolean).pop();

  const [showSubMenu, setShowSubMenu] = useState(
    segment === "users" ||
      segment === "checklist" ||
      segment === "machines" ||
      segment === "pannes" ||
      currentPath.includes("machines/fiche")
      ? true
      : false
  );

  // Function to toggle the visibility of the submenu
  const toggleSubMenu = () => {
    setShowSubMenu(!showSubMenu);
  };
  const userOnline = useAppSelector(Auth);
  return (
    <div className="sidebar-wrapper sidebar-theme">
      <div className="theme-logo">
        <a href="/">
          <img
            src={process.env.PUBLIC_URL + "/assets/assets/icons/icon.ico"}
            className="navbar-logo"
            alt="logo"
            style={{ width: "auto", height: "45px" }}
          />
          <span className="admin-logo">
            ECOPACK<span></span>
          </span>
        </a>
      </div>

      <nav id="compactSidebar">
        <ul className="menu-categories">
          <li className="menu active">
            <a
              href="/"
              className="menu-toggle"
              data-active={!segment ? true : false}
            >
              <div className="base-menu">
                <div className="base-icons">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-home"
                  >
                    <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path>
                    <polyline points="9 22 9 12 15 12 15 22"></polyline>
                  </svg>
                </div>
                <span>Dashboard</span>
              </div>
            </a>
          </li>
          {(userOnline && userOnline.user.isadmin) ||
          (userOnline &&
            userOnline.user.role_code === "Responsble Maintenance") ? (
            <li className="menu active">
              <a
                href="/calendar"
                className="menu-toggle"
                data-active={segment === "calendar" ? true : false}
              >
                <div className="base-menu">
                  <div className="base-icons">
                    <i className="fas fa-calendar-alt"></i>{" "}
                  </div>
                  <span>Calendrier Preventif</span>
                </div>
              </a>
            </li>
          ) : null}
          {/* <button onClick={() => console.log(userOnline?.user.role_code)}>
            test btn{" "}
          </button> */}
          {(userOnline?.user.role_code === "admin" ||
            userOnline?.user.role_code === "Responsble Maintenance") && (
            <li className="menu active">
              <a
                href="/analyses"
                className="menu-toggle"
                data-active={segment === "analyses" ? true : false}
              >
                <div className="base-menu">
                  <div className="base-icons">
                    <i className="fas fa-chart-line"></i>
                  </div>
                  <span>Analyses</span>
                </div>
              </a>
            </li>
          )}

          {userOnline && userOnline.user.isadmin === 1 ? (
            <>
              <li className="menu active">
                <a
                  href="/prodprograms"
                  className="menu-toggle"
                  data-active={segment === "prodprograms" ? true : false}
                >
                  <div className="base-menu">
                    <div className="base-icons">
                      <i className="fas fa-calendar-alt"></i>
                    </div>
                    <span>Programme de production</span>
                  </div>
                </a>
              </li>
              <li className="menu active">
                <a
                  href="/notifications"
                  className="menu-toggle"
                  data-active={segment === "notifications" ? true : false}
                >
                  <div className="base-menu">
                    <div className="base-icons">
                      <i className="fas fa-tasks"></i>
                    </div>
                    <span>Tickets</span>
                  </div>
                </a>
              </li>
              <li className="menu active">
                <a
                  href="/interventions"
                  className="menu-toggle"
                  data-active={segment === "interventions" ? true : false}
                >
                  <div className="base-menu">
                    <div className="base-icons">
                      <i className="fas fa-tools"></i>
                    </div>
                    <span>Interventions</span>
                  </div>
                </a>
              </li>
              <li className="menu">
                <a
                  href="#"
                  className="menu-toggle mb-1"
                  onClick={toggleSubMenu}
                >
                  <div className="base-menu">
                    <div className="base-icons">
                      <i className="fas fa-cog"></i>
                    </div>
                    <span>Settings</span>
                  </div>
                </a>
                <ul className={`submenu ${showSubMenu ? "show" : ""}`}>
                  <li className="menu mb-1">
                    <a
                      href="/users"
                      className="menu-toggle"
                      data-active={segment === "users" ? true : false}
                    >
                      <div className="base-menu">
                        <div className="base-icons">
                          <i className="fas fa-users"></i>
                        </div>
                        <span>Utilisateurs</span>
                      </div>
                    </a>
                  </li>
                  <li className="menu mb-1">
                    <a
                      href="/machines"
                      className="menu-toggle"
                      data-active={
                        segment === "machines" ||
                        currentPath?.includes("machines/fiche/")
                          ? true
                          : false
                      }
                    >
                      <div className="base-menu">
                        <div className="base-icons">
                          <i className="fas fa-cogs"></i>
                        </div>
                        <span>Machines</span>
                      </div>
                    </a>
                  </li>
                  <li className="menu mb-1">
                    <a
                      href="/checklist"
                      className="menu-toggle"
                      data-active={
                        segment === "checklist" ||
                        currentPath?.includes("/checklist/")
                          ? true
                          : false
                      }
                    >
                      <div className="base-menu">
                        <div className="base-icons">
                          <i className="fas fa-tasks"></i>
                        </div>
                        <span>Checklists</span>
                      </div>
                    </a>
                  </li>
                  <li className="menu mb-1">
                    <a
                      href="/pannes"
                      className="menu-toggle"
                      data-active={segment === "pannes" ? true : false}
                    >
                      <div className="base-menu">
                        <div className="base-icons">
                          <i className="fas fa-exclamation-triangle"></i>
                        </div>
                        <span>Pannes</span>
                      </div>
                    </a>
                  </li>
                  <li className="menu mb-1">
                    <a
                      href="/produits"
                      className="menu-toggle"
                      data-active={segment === "produits" ? true : false}
                    >
                      <div className="base-menu">
                        <div className="base-icons">
                          <i className="fas fa-exclamation-triangle"></i>
                        </div>
                        <span>Produits</span>
                      </div>
                    </a>
                  </li>
                </ul>
              </li>
            </>
          ) : null}
          <li
            className="menu active"
            style={{ position: "absolute", bottom: "16px" }}
          >
            <a
              href="/"
              className="menu-toggle"
              data-active={false}
              onClick={(e) => dispatch(logout())}
            >
              <div className="base-menu">
                <div className="base-icons">
                  <i className="fas fa-power-off"></i>
                </div>
                <span>Déconnecter</span>
              </div>
            </a>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default Sidebar;
