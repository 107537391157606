import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import NotificationAffectation from "./NotificationAffectation";
import { useAppDispatch, useAppSelector } from "../../_App/Redux/hooks";
import { InterventionParTicket } from "../../_App/Redux/Slices/interventions/interventionSlice";
import FicheInterventionModal from "../Interventions/FicheInterventionModal";
import { InterventionType } from "../../_App/Types/Entites/InterventionType";
import no_image from "../../../public/assets/assets/img/no-image.jpg";
import {
  ImagesTickets,
  updateTicket,
} from "../../_App/Redux/Slices/tickets/ticketSlice";
import ZoomableImage from "../imagePreview/ImagePreview";
import Swal from "sweetalert2";

const Ticket = ({
  ShowModal,
  setShowModal,
  setSelectedTicket,
  selectedTicket,
  setShowIntervention,
  setSelectedIntervention,
  stop,
}: any) => {
  const [ShowModalAffectation, setShowModalAffectation] = useState(false);
  const interventions = useAppSelector(InterventionParTicket);
  const images = useAppSelector(ImagesTickets);
  const dispatch = useAppDispatch();
  const clotureTicket = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        const res = dispatch(
          updateTicket({ id: selectedTicket.id, newData: { cloture: 1 } })
        );
        Swal.fire({
          title: "Deleted!",
          text: "Your file has been deleted.",
          icon: "success",
        }).then((result) => {
          if (result.isConfirmed) {
            setShowModal(false);
          }
        });
      }
    });
  };
  return (
    <div>
      <NotificationAffectation
        ShowModal={ShowModalAffectation}
        setShowModal={setShowModalAffectation}
        notification={selectedTicket}
      />

      <Modal
        show={ShowModal}
        onHide={() => {
          setSelectedTicket(undefined);
          setShowModal(false);
        }}
        dialogClassName="modal-transparent" // Add this class for styling
        centered
      >
        <Modal.Body>
          <div className="signboard outer">
            <div className="signboard front inner anim04c">
              <div className="app">
                <main className="project" style={{ height: "100%" }}>
                  <div className="project-info"></div>
                  <div className="project-tasks">
                    <div className="project-column">
                      <div className="task" draggable="true">
                        <div className="task__tags">
                          <span
                            className="task__tag task__tag--copyright"
                            style={{ textTransform: "uppercase" }}
                          >
                            <i className="fas fa-cogs"></i>
                            {"  Machine " +
                              selectedTicket?.Machine?.nom +
                              " - Type : " +
                              selectedTicket?.type}
                          </span>
                          <span
                            className="task__tag task__tag--copyright"
                            style={{ textTransform: "uppercase" }}
                          >
                            {"  ODP " + selectedTicket?.odf_code}
                          </span>
                          <button
                            className="task__options"
                            onClick={() => {
                              setSelectedTicket(undefined);
                              setShowModal(false);
                            }}
                          >
                            <i className="fas fa-times"></i>
                          </button>
                        </div>
                        <div className="row">
                          <div className="col-6">
                            
                            {selectedTicket &&
                            images[selectedTicket.code] &&
                            images[selectedTicket.code][0] ? (
                              <ZoomableImage
                                size={170}
                                imageUrl={
                                  images[selectedTicket.code][0]?.path ||
                                  "/assets/assets/img/no-image.jpg"
                                }
                              />
                            ) : (
                              <img
                                src={"assets/assets/img/no-image.jpg"}
                                width={170}
                                height={170}
                                alt="zoomable"
                              />
                            )}
                          </div>

                          <div className="col-6">
                            {" "}
                            <div className="project-column-heading">
                              <h2 className="project-column-heading__title">
                                {selectedTicket?.objet}
                              </h2>
                            </div>
                            <div className="project-column-heading">
                              <h6
                                className="project-column-heading__title"
                                style={{ fontSize: "16px" }}
                              >
                                {selectedTicket?.Panne
                                  ? "Panne : " + selectedTicket?.Panne?.libelle
                                  : null}
                              </h6>
                            </div>
                            <p className="m-0">{selectedTicket?.description}</p>
                          </div>
                        </div>
                        <div className="row">
                          <div className="task__stats">
                            <span>
                              <time>
                                <i className="fas fa-flag"></i>
                                {selectedTicket?.date.toString().split("T")[0]}
                              </time>
                            </span>
                            <span>
                              <i className="fas fa-user"></i>
                              {selectedTicket?.CreatorUser?.nom +
                                " " +
                                selectedTicket?.CreatorUser?.prenom}
                            </span>
                            {selectedTicket?.cloture === 0 && (
                              <button
                                className="btn btn-danger"
                                style={{ float: "right" }}
                                onClick={() => clotureTicket()}
                              >
                                Cloturer
                              </button>
                            )}

                            {selectedTicket && selectedTicket.affected_to ? (
                              <span className="affectation">
                                <i className="fas fa-recycle"></i>{" "}
                                {"Affecté a " +
                                  selectedTicket?.UserAffectedTo?.nom +
                                  " " +
                                  selectedTicket?.UserAffectedTo?.prenom}
                              </span>
                            ) : selectedTicket &&
                              selectedTicket.affected_to &&
                              selectedTicket.cloture === 0 ? (
                              <span
                                className="task__owner"
                                onClick={() => setShowModalAffectation(true)}
                              >
                                <i className="fas fa-recycle"></i>Affecter
                              </span>
                            ) : null}
                          </div>
                        </div>

                        {!stop &&
                        interventions &&
                        selectedTicket &&
                        interventions[selectedTicket.code] &&interventions[selectedTicket.code].length ? (
                          <>
                            <div className="project-column-heading mb-0">
                              <h2 className="project-column-heading__title">
                                Interventions :
                              </h2>
                            </div>
                            <div className="row">
                              {interventions[selectedTicket.code].map(
                                (item, index) => (
                                  <span
                                    key={index}
                                    onClick={() => {
                                      setSelectedIntervention(item);
                                      setShowIntervention(true);
                                    }}
                                    className="usr-ph-no"
                                    style={{
                                      textDecoration: "underline",
                                      color: "#4361ee",
                                      cursor: "pointer",
                                    }}
                                  >
                                    {" "}
                                    {item.InterventionAction?.libelle}{" "}
                                  </span>
                                )
                              )}
                            </div>
                          </>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </main>
              </div>
            </div>

            <div className="signboard left inner anim04c"></div>
            <div className="signboard right inner anim04c"></div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Ticket;
